import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./accessoires.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"

const Accessoires = () => {
  const data = useStaticQuery(graphql`
    query {
      casque: file(
        relativePath: { eq: "images/accessoires/casques/casque3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      antivol: file(
        relativePath: { eq: "images/accessoires/antivols/antivol1.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sacoche: file(
        relativePath: { eq: "images/accessoires/sacoches/sacoches1.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vetements: file(
        relativePath: { eq: "images/accessoires/vetements/vetement1.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="accessoires pour vélos électriques"
        description="Sélection des accessoires pour vélos électriques"
      />
      <main className={styles.main}>
        <h1>Accessoires</h1>
        <section className={styles.gridContainer}>
          <article className={styles.card}>
            <Link to="/casques">
              <div className={styles.insideContainerCard}>
                <Img
                  className={styles.image}
                  fluid={data.casque.childImageSharp.fluid}
                  alt="casque de vélo bleu ciel de la marque Kask"
                />
                <p>Casques</p>
              </div>
            </Link>
          </article>
          <article className={styles.card}>
            <Link to="/antivols">
              <section className={styles.insideContainerCard}>
                <Img
                  className={styles.image}
                  fluid={data.antivol.childImageSharp.fluid}
                  alt="antivol pliable de la marque Trelock"
                />

                <p>Antivols</p>
              </section>
            </Link>
          </article>
          <article className={styles.card}>
            <Link to="/sacoches">
              <section className={styles.insideContainerCard}>
                <Img
                  className={styles.image}
                  fluid={data.sacoche.childImageSharp.fluid}
                  alt="paire de sacoches de vélo rouges de la marque Vaude"
                />
                <p>Sacoches</p>
              </section>
            </Link>
          </article>
          <article className={styles.card}>
            <Link to="/vetements">
              <section className={styles.insideContainerCard}>
                <Img
                  className={styles.image}
                  fluid={data.vetements.childImageSharp.fluid}
                  alt="veste de pluie de vélo jaune"
                />
                <p>Vêtements</p>
              </section>
            </Link>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Accessoires
